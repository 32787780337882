export const MAIN_URL = "https://api.geo.intellideep.digital"
export const IMAGE_URL = "https://api.geo.intellideep.digital/static/images/countries"
// export const MAIN_URL = "https://localhost:5000"
// export const IMAGE_URL = "https://localhost:5000/static/images/countries"
// export const MAIN_URL = "http://localhost:3000"
// export const IMAGE_URL = "http://localhost/static/images/countries"
export const intITEMS_PER_PAGE = 300
export const USERNAME = "username"
export const PASSWORD = "password"
export const LOGIN = "login"
export const RIGHT = "right"
export const TEXT = "text"
export const GET = "GET"
export const POST = "POST"
export const PUT = "PUT"
export const DELETE = "DELETE"
export const ERROR_MESSAGE = "error_message"
export const ERROR_VISIBLE = "error_visible"
export const ERROR_HIGHLIGHT = "error_highlight"
export const ERROR_HIDDEN = "error_hidden"
export const LONG_ERROR = "long_error"
export const NETWORK_ERROR = "Error: Cannot connect to server. Please make sure your internet connection is working" +
                             " and try to refresh the page."
export const AUTH_ERROR = "Invalid credentials. Please make sure your username and password are correct."
export const SERVER_ERROR = "A server error has occurred. Please try again later. If the problem persists, please" +
                            " contact the system administrator."
export const UNKNOWN_ERROR = "An unknown error has occurred. Please try again later. If the problem persists, please" +
                             " contact the system administrator."
export const DRAFT_LIST_HEADING = "Drafts"
export const PUBLISHED_LIST_HEADING = "Published"
export const MOVED_TO_DRAFTS = "Card has been moved to drafts."
export const MOVED_TO_PUBLISHED = "Card published successfully."

export const LOGGED_IN_COOKIE = "logged-in"
export const CREATE_CARD_HEADING = "Add A New Country"
export const UPDATE_CARD_HEADING = "Edit Country"

export const INTELLIGEO_LOGO = "intelligeo_logo"
export const INTELLI = "intelli"
export const GEO = "geo"
export const TOP_BAR = "top_bar"
export const TOP_BAR_CONTAINER = "top_bar_container"
export const TOP_BAR_LEFT = "top_bar_left"
export const TOP_BAR_RIGHT = "top_bar_right"
export const NAV_LINKS = "nav_links"
export const NAV_ITEM = "nav_item"
export const NAV_LINK = "nav_link"
export const BUTTON = "button"
export const BUTTON_CONTAINER = "button_container"
export const TIMESTAMP_CONTAINER = "timestamp_container"
export const TIMESTAMP = "timestamp"
export const HEADING = "heading"
export const HEADING2 = "heading2"
export const YES = "yes"
export const NO  = "no"
export const UNDO = "undo"
export const INDEX = "index"
export const DISABLED = "disabled"
export const HOVER_TEXT = "hover_text"
export const TOOLTIP = "tooltip"
export const TOOLTIP_CONTAINER = "tooltip_container"
export const TITLE = "title"
export const DESCRIPTION = "description"
export const FOOTER = "footer"
export const COPYRIGHT = "copyright"
export const APP_CONTENT = "app_content"
export const SHOW_MENU = "show_menu"
export const SHOW_MENU_TRANSLATE = "show_menu_translate"
export const HIDE_MENU_TRANSLATE = "hide_menu_translate"
export const ROTATE_RIGHT = "rotate_right"
export const ROTATE_LEFT = "rotate_left"

export const PAGE_NUM = "page_num"
export const ITEMS_PER_PAGE = "items_per_page"
export const OUTER_CONTAINER = "outer_container"
export const INNER_CONTAINER = "inner_container"
export const SUBHEADING = "subheading"
export const SUB_SUBHEADING = "sub_subheading"
export const SUB_SUBHEADING_CONTAINER = "sub_subheading_container"
export const INVISIBLE = "invisible"
export const VISIBLE = "visible"
export const HOME = "home"
export const FOOTER_CONTENT = "footer_content"
export const PAGE_CONTENT = "page_content"
export const BLURIFY = "blurify"

export const DRAFT = "Draft"
export const PUBLISHED = "Published"
export const LOGIN_PAGE  = "login_page"
export const LOGOUT = "logout"
export const INPUT_CONTAINER  = "input_container"
export const PAGE_SUB_HEADING = "page_sub_heading"
export const PAGE_HEADING_SECTION = "page_heading_section"
export const CARD = "card"
export const IMAGE = "image"
export const CARD_CONTAINER = "card_container"
export const CARD_CONTENT = "card_content"
export const SUBSECTION = "subsection"
export const META = "meta"
export const COUNTRY_NAME = "Country name"
export const CARDS_LIST_OUTER = "cards_list_outer"
export const CARDS_LIST_CONTAINER = "cards_list_container"
export const SINGLE_CARDS_PAGE = "single_card_page"
export const EDIT_CARD = "edit_card"
export const ADD_FIELD = "add_field"
export const DELETE_FIELD = "delete_field"
export const DANGER = "danger"
export const DELETE_CARD = "delete_card"
export const DELETE_IMAGE = "delete_image"
export const INPUT_FIELD_CONTAINER = "input_field_container"
export const QUESTION_ANSWERS = "question_answers"
export const TOGGLE_SLIDER = "toggle_slider"
export const SLIDER_CONTAINER = "slider_container"
export const CREATE = "create"
export const UPDATE = "update"
export const ADD_CARD = "Add Card"
export const UPDATE_CARD = "Update Card"
export const CLUES = "clues"
export const NAME = "name"
export const FILENAME = "filename"
export const UPLOAD_IMAGE = "upload_image"
export const UPLOAD_IMAGE_CONTAINER = "upload_image_container"
export const LATEST_CLUE_ADD = "latest_clue_add"
export const MODAL = "modal"
export const MODAL_MESSAGE = "modal_message"
export const MODAL_HEADING = "modal_heading"
export const BACK_ARROW = "back_arrow"
export const MENU_ARROW = "menu_arrow"
export const LOADING = "loading"
export const LOADING_TEXT = "loading_text"
export const SVG_PATH = "svg_path"
export const SVG_G = "svg_g"
export const QUESTION_ANS = "questionAns"
export const PHOTO_CREDIT_CONTAINER = "photo_credit_container"
export const PHOTO_CREDIT = "photo_credit"
export const NO_PHOTO_CREDIT = "no_photo_credit"
export const IMAGE_INFO = "imageInfo"

// Endpoints
export const EP_COUNTRY = "/country"
export const EP_COUNTRY_IMAGE = "/country/image"
export const EP_COUNTRIES = "/countries"
export const EP_COUNTRIES_TOTAL = "/countries/total"
export const EP_PUBLISHED = "/published"
export const EP_PUBLISH = "/publish"
export const EP_PUBLISHED_TOTAL = "/published/total"

// Paths
export const PATH_COUNTRY = "/country"
export const PATH_HOME = "/home"
export const PATH_DRAFTS = "/drafts"
export const PATH_CREATE = "/create"
export const PATH_UPDATE = "/update"
export const NETWORK_ERROR_CONTAINER = "network_error_container"
export const IMAGE_THUMBNAIL = "image_thumbnail"
export const ANS = "ans"
export const SUBMIT_MESSAGE = "submit_message"
export const PUBLISH_MESSAGE = "publish_message"
export const SUBMIT = "submit"


export const get_url = (url, ...args) => {
    return url + "?" + args.reduce((arg1, arg2) => arg1 + "&" + arg2)
}

export const sleep = async(seconds) => {
    return new Promise(resolve => setTimeout(resolve, 1000*seconds))
}
